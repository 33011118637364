//colors
$membership-color: #e1261d;
$red: (
  default: #e1261d,
  dark: #c2132b,
  light: #feecec,
);
$green: (
  default: #00bca1,
);
$grey: (
  default: #8d8d8d,
  light: #e1e1e1,
  fog: #f5f6f6,
  border: #dfdfdf,
  text: #6d6d6d,
  textlight: #180202,
  dark: #1e1e1e,
  darkest: #1f1f1f,
  caption: #2c2c31,
  background: #f5f5f5,
  bgcard: #f2f1f1,
  placeholder: #c7c7c7,
  button: #48484833,
  list: #484848,
  checkbox: #c1c1c1,
  switch: #c0c0c0,
  thankyou: #979797,
  price: #c4c4c4,
  deny: #979797,
);
$alerts: (
  error_bg: #feecec,
  success: #00bca1,
  success_bg: rgba(0, 188, 160, 0.102),
  notification: #9a5c00,
  notification_bg: #fbe7b6,
);
$flex: (
  default: #feeff1,
  border: #fed1d8,
  bg: #00bca1,
);
$white: #ffffff;
$black: #000000;
$discount: #2bb8a4;
$unavailable: #ee203c;
$messages: #d9ddf4;
$flags: #fef4f5;
$enjoy: #c40e24;

//spacing
$space-unit: 8px;
$cart-bar: 92px;
$header-height-desktop: 80px;
$header-height-mobile: 64px;

//fonts
// paragraph: 'SFProText',
// paragraph_small: 'SFProDisplay'
// prettier-ignore
$font: (
  primary: 'Montserrat Alternates',
  secondary: 'Montserrat',
  paragraph: 'Roboto',
  paragraph_small: 'Roboto', //inutilizzato al momento
);

//breakpoints
$screen: (
  xs: 375px,
  sm: 768px,
  md: 1024px,
  mdlg: 1200px,
  lg: 1440px,
  xl: 1440px,
);

//screen paddings
$spacings: (
  xs: $space-unit * 3,
  sm: $space-unit * 3,
  md: $space-unit * 3,
  lg: $space-unit * 3,
  xl: $space-unit * 10,
);
