@import "../core/exports";
@import "../frame/frame";

@include exports("scroller") {

  .mbsc-sc-whl-o,
  .mbsc-sc-btn {
    /* Prevent flickering on animation */
    -webkit-transform: translateZ(0);
  }

  /* Force content box */

  .mbsc-sc .mbsc-sc-whl-c,
  .mbsc-sc .mbsc-sc-whl-l,
  .mbsc-sc .mbsc-sc-whl {
    box-sizing: content-box;
  }

  /* Force border box */

  .mbsc-sc-whl-gr-c,
  .mbsc-sc-itm {
    box-sizing: border-box;
  }

  .mbsc-sc-whl-gr-c {
    position: relative;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
  }

  .mbsc-fr-bottom .mbsc-sc-whl-gr-c:first-child:last-child,
  .mbsc-fr-top .mbsc-sc-whl-gr-c:first-child:last-child,
  .mbsc-fr-inline .mbsc-sc-whl-gr-c:first-child:last-child,
  .mbsc-fr-liq .mbsc-sc-whl-gr-c {
    display: block;
  }

  .mbsc-sc-whl-gr {
    margin: 0 auto;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .mbsc-sc-whl-w {
    max-width: 100%;
    position: relative;
    -ms-touch-action: none;
    touch-action: none;
  }

  .mbsc-fr-pointer .mbsc-sc-whl-w,
  .mbsc-fr-liq .mbsc-sc-whl-w {
    -webkit-box-flex: 1;
    -webkit-flex: 1 auto;
    -ms-flex: 1 auto;
    flex: 1 auto;
  }

  .mbsc-sc-whl-o {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    display: none;
  }

  .mbsc-sc-whl-l {
    display: none;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    pointer-events: none;
  }

  .mbsc-sc-whl-w .mbsc-sc-whl-l {
    display: block;
  }

  .mbsc-sc-whl {
    overflow: hidden;
    /* Forces IE to respect overflow hidden while animating */
    /* Looks like this is not needed, also, it brakes rendering on Samsung S5 Mini */
    /* border-radius: 1px; */
    /* Fixes Firefox rendering issues */
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    margin: -1px 0;
  }

  .mbsc-sc-whl-c {
    position: relative;
    z-index: 1;
    top: 50%;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }

  .mbsc-sc-whl-sc {
    position: relative;
  }

  .mbsc-sc-itm {
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mbsc-sc-itm-inv,
  .mbsc-sc-itm-inv-h {
    opacity: .3;
  }

  .mbsc-sc-lbl {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    display: none;
  }

  .mbsc-sc-lbl-v .mbsc-sc-lbl {
    display: block;
  }

  .mbsc-sc-btn {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    cursor: pointer;
    opacity: 1;
    text-align: center;
    transition: opacity .2s linear;
  }

  .mbsc-sc-btn:before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  .mbsc-sc-whl-a .mbsc-sc-btn {
    opacity: 0;
  }

  .mbsc-sc-btn-plus {
    bottom: 0;
  }

  .mbsc-sc-btn-minus {
    top: 0;
  }

  /* 3D */

  .mbsc-sc-whl-gr-3d {
    -webkit-perspective: 1200px;
    perspective: 1200px;
  }

  .mbsc-sc-whl-gr-3d .mbsc-sc-whl {
    /* For iOS to respect z-index */
    overflow: visible;
  }

  .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
    overflow: hidden;
  }

  .mbsc-sc-whl-gr-3d .mbsc-sc-whl-w,
  .mbsc-sc-whl-gr-3d .mbsc-sc-whl {
    /* For Edge and Firefox */
    transform-style: preserve-3d;
  }

  .mbsc-sc-whl-3d {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }

  .mbsc-sc-itm-3d {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .mbsc-sc-itm-del {
    display: none;
  }

  /* Multiline */

  .mbsc-sc-itm-ml {
    width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
  }

  /* Multiple */

  .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    text-align: center;
  }

  /* Desktop view */

  .mbsc-fr-pointer {

    .mbsc-fr-w .mbsc-sc-whl-gr-c,
    .mbsc-fr-w .mbsc-sc-whl-gr {
      padding: 0;
    }

    .mbsc-sc-whl-gr-c:first-child:last-child {
      display: block;
    }
  }

  .mbsc-sc-bar-c {
    position: absolute;
    z-index: 4;
    top: 0;
    bottom: 0;
    right: 0;
    width: 10px;
    opacity: 0;
    background: rgba(0, 0, 0, .05);
    transform: translateZ(0);
    transition: opacity .2s;
  }

  .mbsc-sc-bar {
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
    height: 100%;
  }

  .mbsc-sc-bar:after {
    content: '';
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    background: rgba(0, 0, 0, .5);
    border-radius: 3px;
  }

  .mbsc-sc-whl-w:hover .mbsc-sc-bar-c,
  .mbsc-sc-whl-anim .mbsc-sc-bar-c {
    opacity: 1;
  }
}
