@import "../core/exports";
@import "../calbase/calbase.mobiscroll";
@import "./range.mobiscroll.colors";
@import "./range";

@include exports("range.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-range-btn-t {
      padding: .5em .25em 0 .25em;
    }

    .mbsc-range-btn {
      margin: 0 .3333em;
      padding: .3333em;
      font-size: .75em;
      line-height: 1.666667em;
    }

    &.mbsc-range {
      .mbsc-fr-w {
        padding: 0;
      }

      .mbsc-fr-hdr {
        padding-top: .6666em;
      }

      .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-selected .mbsc-cal-day-date {
        background: none;
        border-color: transparent;
      }

      .mbsc-cal-day.mbsc-selected:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: .25em;
        left: 0;
        right: 0;
        height: 2em;
      }

      .mbsc-ltr .mbsc-cal-sel-start:after,
      .mbsc-rtl .mbsc-cal-sel-end:after {
        left: 50%;
        margin-left: -1em;
      }

      .mbsc-ltr .mbsc-cal-sel-end:after,
      .mbsc-rtl .mbsc-cal-sel-start:after {
        right: 50%;
        margin-right: -1em;
      }

      .mbsc-ltr .mbsc-cal-sel-start:after,
      .mbsc-ltr .mbsc-cal-day:first-child:after,
      .mbsc-ltr .mbsc-cal-hide-diff .mbsc-cal-day-first:after,
      .mbsc-rtl .mbsc-cal-sel-end:after,
      .mbsc-rtl .mbsc-cal-day:last-child:after,
      .mbsc-rtl .mbsc-cal-hide-diff .mbsc-cal-day-last:after {
        border-top-left-radius: 2em;
        border-bottom-left-radius: 2em;
      }

      .mbsc-ltr .mbsc-cal-sel-end:after,
      .mbsc-ltr .mbsc-cal-day:last-child:after,
      .mbsc-ltr .mbsc-cal-hide-diff .mbsc-cal-day-last:after,
      .mbsc-rtl .mbsc-cal-sel-start:after,
      .mbsc-rtl .mbsc-cal-day:first-child:after,
      .mbsc-rtl .mbsc-cal-hide-diff .mbsc-cal-day-first:after {
        border-top-right-radius: 2em;
        border-bottom-right-radius: 2em;
      }
    }
  }

  @include mbsc-mobiscroll-range(mobiscroll, $mbsc-mobiscroll-colors);
}
