@import "../core/exports";
@import "../core/mobiscroll";
@import "../calbase/calbase.mobiscroll.colors";

@mixin mbsc-mobiscroll-range($theme, $params) {
  @include exports("range.#{$theme}.colors") {

    @include mbsc-mobiscroll-calbase($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    $background-param: map-get($params, 'frame-background');
    $text-param: map-get($params, 'frame-text');
    $accent-param: map-get($params, 'frame-accent');

    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);

    .mbsc-#{$theme} {
      .mbsc-range-btn {
        border: 1px solid $accent;
      }

      &.mbsc-range {
        .mbsc-range-btn-t .mbsc-range-btn.mbsc-selected {
          background: $accent;
          color: $background;
        }

        .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-selected .mbsc-cal-day-date {
          background: none;
          border-color: transparent;
          color: $text;
        }

        .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-cal-day-hl .mbsc-cal-day-date {
          background: $accent;
          border-color: $accent;
          color: $background;
        }

        .mbsc-cal-day.mbsc-selected:after {
          background: rgba($accent, .3);
        }
      }
    }
  }
}
