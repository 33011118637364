@import "./exports";

@include exports("animation") {

  .mbsc-anim-trans .mbsc-fr-scroll {
    overflow: hidden;
  }

  .mbsc-anim-trans-flip .mbsc-fr-persp,
  .mbsc-anim-trans-swing .mbsc-fr-persp {
    -webkit-perspective: 1000px;
    perspective: 1000px;
  }

  .mbsc-anim-trans .mbsc-fr-popup,
  .mbsc-anim-trans .mbsc-fr-overlay {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .mbsc-anim-in .mbsc-fr-popup,
  .mbsc-anim-in .mbsc-fr-overlay {
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-duration: 225ms;
    animation-timing-function: ease-out;
    animation-duration: 225ms;
  }

  .mbsc-anim-out .mbsc-fr-popup,
  .mbsc-anim-out .mbsc-fr-overlay {
    -webkit-animation-timing-function: ease-in;
    -webkit-animation-duration: 195ms;
    animation-timing-function: ease-in;
    animation-duration: 195ms;
  }

  .mbsc-anim-in .mbsc-fr-overlay {
    -webkit-animation-name: mbsc-anim-f-in;
    animation-name: mbsc-anim-f-in;
  }

  .mbsc-anim-out .mbsc-fr-overlay {
    -webkit-animation-name: mbsc-anim-f-out;
    animation-name: mbsc-anim-f-out;
  }

  .mbsc-anim-flip,
  .mbsc-anim-swing,
  .mbsc-anim-slidehorizontal,
  .mbsc-anim-slidevertical,
  .mbsc-anim-slidedown,
  .mbsc-anim-slideup,
  .mbsc-anim-fade {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateX(0);
    backface-visibility: hidden;
    transform: translateX(0);
  }

  .mbsc-anim-swing,
  .mbsc-anim-slidehorizontal,
  .mbsc-anim-slidevertical,
  .mbsc-anim-slidedown,
  .mbsc-anim-slideup,
  .mbsc-anim-fade {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }

  .mbsc-anim-flip,
  .mbsc-anim-pop {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  .mbsc-anim-in .mbsc-anim-pop {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-p-in;
    -webkit-animation-duration: 100ms;
    -webkit-transform: scale(1);
    animation-name: mbsc-anim-p-in;
    animation-duration: 100ms;
    transform: scale(1);
  }

  .mbsc-anim-out .mbsc-anim-pop {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-p-out;
    -webkit-animation-duration: 150ms;
    animation-name: mbsc-anim-p-out;
    animation-duration: 150ms;
  }

  .mbsc-anim-trans-pop .mbsc-fr-overlay {
    -webkit-animation-duration: 150ms;
    animation-duration: 150ms;
  }

  .mbsc-anim-in .mbsc-anim-flip {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-fl-in;
    -webkit-transform: scale(1);
    animation-name: mbsc-anim-fl-in;
    transform: scale(1);
  }

  .mbsc-anim-out .mbsc-anim-flip {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-fl-out;
    animation-name: mbsc-anim-fl-out;
  }

  .mbsc-anim-in .mbsc-anim-swing {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-sw-in;
    -webkit-transform: scale(1);
    transform: scale(1);
    animation-name: mbsc-anim-sw-in;
  }

  .mbsc-anim-out .mbsc-anim-swing {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-sw-out;
    animation-name: mbsc-anim-sw-out;
  }

  .mbsc-anim-in .mbsc-anim-slidehorizontal {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-sh-in;
    -webkit-transform: scale(1);
    transform: scale(1);
    animation-name: mbsc-anim-sh-in;
  }

  .mbsc-anim-out .mbsc-anim-slidehorizontal {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-sh-out;
    animation-name: mbsc-anim-sh-out;
  }

  .mbsc-anim-in .mbsc-anim-slidevertical {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-sv-in;
    -webkit-transform: scale(1);
    animation-name: mbsc-anim-sv-in;
    transform: scale(1);
  }

  .mbsc-anim-out .mbsc-anim-slidevertical {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-sv-out;
    animation-name: mbsc-anim-sv-out;
  }

  .mbsc-anim-in .mbsc-anim-slidedown {
    -webkit-animation-name: mbsc-anim-sd-in;
    -webkit-transform: scale(1);
    animation-name: mbsc-anim-sd-in;
    transform: scale(1);
  }

  .mbsc-anim-out .mbsc-anim-slidedown {
    -webkit-animation-name: mbsc-anim-sd-out;
    -webkit-transform: translateY(-100%);
    animation-name: mbsc-anim-sd-out;
  }

  .mbsc-anim-in .mbsc-anim-slideup {
    -webkit-animation-name: mbsc-anim-su-in;
    -webkit-transform: scale(1);
    transform: scale(1);
    animation-name: mbsc-anim-su-in;
  }

  .mbsc-anim-out .mbsc-anim-slideup {
    -webkit-animation-name: mbsc-anim-su-out;
    -webkit-transform: translateY(100%);
    animation-name: mbsc-anim-su-out;
  }

  .mbsc-anim-in .mbsc-anim-fade {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-f-in;
    animation-name: mbsc-anim-f-in;
  }

  .mbsc-anim-out .mbsc-anim-fade {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-f-out;
    animation-name: mbsc-anim-f-out;
  }

  .mbsc-fr-pointer {
    &.mbsc-anim-in .mbsc-anim-slidedown {
      -webkit-animation-name: mbsc-anim-sd-in, mbsc-anim-f-in;
      animation-name: mbsc-anim-sd-in, mbsc-anim-f-in;
    }

    &.mbsc-anim-out .mbsc-anim-slidedown {
      -webkit-animation-name: mbsc-anim-sd-out, mbsc-anim-f-out;
      animation-name: mbsc-anim-sd-out, mbsc-anim-f-out;
    }

    &.mbsc-anim-in .mbsc-anim-slideup {
      -webkit-animation-name: mbsc-anim-su-in, mbsc-anim-f-in;
      animation-name: mbsc-anim-su-in, mbsc-anim-f-in;
    }

    &.mbsc-anim-out .mbsc-anim-slideup {
      -webkit-animation-name: mbsc-anim-su-out, mbsc-anim-f-out;
      animation-name: mbsc-anim-su-out, mbsc-anim-f-out;
    }
  }

  /* Fade in */

  @keyframes mbsc-anim-f-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes mbsc-anim-f-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }


  /* Fade out */

  @keyframes mbsc-anim-f-out {
    from {
      visibility: visible;
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @-webkit-keyframes mbsc-anim-f-out {
    from {
      visibility: visible;
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }


  /* Pop in */

  @keyframes mbsc-anim-p-in {
    from {
      opacity: 0;
      transform: scale(0.8);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @-webkit-keyframes mbsc-anim-p-in {
    from {
      opacity: 0;
      -webkit-transform: scale(0.8);
    }

    to {
      opacity: 1;
      -webkit-transform: scale(1);
    }
  }


  /* Pop out */

  @keyframes mbsc-anim-p-out {
    from {
      opacity: 1;
      transform: scale(1);
    }

    to {
      opacity: 0;
      transform: scale(0.8);
    }
  }

  @-webkit-keyframes mbsc-anim-p-out {
    from {
      opacity: 1;
      -webkit-transform: scale(1);
    }

    to {
      opacity: 0;
      -webkit-transform: scale(0.8);
    }
  }


  /* Flip in */

  @keyframes mbsc-anim-fl-in {
    from {
      opacity: 0;
      transform: rotateY(90deg);
    }

    to {
      opacity: 1;
      transform: rotateY(0);
    }
  }

  @-webkit-keyframes mbsc-anim-fl-in {
    from {
      opacity: 0;
      -webkit-transform: rotateY(90deg);
    }

    to {
      opacity: 1;
      -webkit-transform: rotateY(0);
    }
  }


  /* Flip out */

  @keyframes mbsc-anim-fl-out {
    from {
      opacity: 1;
      transform: rotateY(0deg);
    }

    to {
      opacity: 0;
      transform: rotateY(-90deg);
    }
  }

  @-webkit-keyframes mbsc-anim-fl-out {
    from {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
    }

    to {
      opacity: 0;
      -webkit-transform: rotateY(-90deg);
    }
  }


  /* Swing in */

  @keyframes mbsc-anim-sw-in {
    from {
      opacity: 0;
      transform: rotateY(-90deg);
    }

    to {
      opacity: 1;
      transform: rotateY(0deg);
    }
  }

  @-webkit-keyframes mbsc-anim-sw-in {
    from {
      opacity: 0;
      -webkit-transform: rotateY(-90deg);
    }

    to {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
    }
  }


  /* Swing out */

  @keyframes mbsc-anim-sw-out {
    from {
      opacity: 1;
      transform: rotateY(0deg);
    }

    to {
      opacity: 0;
      transform: rotateY(-90deg);
    }
  }

  @-webkit-keyframes mbsc-anim-sw-out {
    from {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
    }

    to {
      opacity: 0;
      -webkit-transform: rotateY(-90deg);
    }
  }


  /* Slide horizontal in */

  @keyframes mbsc-anim-sh-in {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @-webkit-keyframes mbsc-anim-sh-in {
    from {
      opacity: 0;
      -webkit-transform: translateX(-100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }


  /* Slide horizontal out */

  @keyframes mbsc-anim-sh-out {
    from {
      opacity: 1;
      transform: translateX(0);
    }

    to {
      opacity: 0;
      transform: translateX(100%);
    }
  }

  @-webkit-keyframes mbsc-anim-sh-out {
    from {
      opacity: 1;
      -webkit-transform: translateX(0);
    }

    to {
      opacity: 0;
      -webkit-transform: translateX(100%);
    }
  }


  /* Slide vertical in */

  @keyframes mbsc-anim-sv-in {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @-webkit-keyframes mbsc-anim-sv-in {
    from {
      opacity: 0;
      -webkit-transform: translateY(-100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }


  /* Slide vertical out */

  @keyframes mbsc-anim-sv-out {
    from {
      opacity: 1;
      transform: translateY(0);
    }

    to {
      opacity: 0;
      transform: translateY(100%);
    }
  }

  @-webkit-keyframes mbsc-anim-sv-out {
    from {
      opacity: 1;
      -webkit-transform: translateY(0);
    }

    to {
      opacity: 0;
      -webkit-transform: translateY(100%);
    }
  }


  /* Slide Down In */

  @keyframes mbsc-anim-sd-in {
    from {
      transform: translateY(-100%);
    }

    to {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes mbsc-anim-sd-in {
    from {
      opacity: 1;
      -webkit-transform: translateY(-100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }


  /* Slide down out */

  @keyframes mbsc-anim-sd-out {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(-100%);
    }
  }

  @-webkit-keyframes mbsc-anim-sd-out {
    from {
      opacity: 1;
      -webkit-transform: translateY(0);
    }

    to {
      opacity: 1;
      -webkit-transform: translateY(-100%);
    }
  }


  /* Slide Up In */

  @keyframes mbsc-anim-su-in {
    from {
      transform: translateY(100%);
    }

    to {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes mbsc-anim-su-in {
    from {
      opacity: 1;
      -webkit-transform: translateY(100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }


  /* Slide up out */

  @keyframes mbsc-anim-su-out {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(100%);
    }
  }

  @-webkit-keyframes mbsc-anim-su-out {
    from {
      opacity: 1;
      -webkit-transform: translateY(0);
    }

    to {
      opacity: 1;
      -webkit-transform: translateY(100%);
    }
  }
}
