@import '../core/exports';
@import '../scroller/scroller';

@include exports('calbase') {
  /* Box sizing */

  .mbsc-cal-slide,
  .mbsc-cal-day,
  .mbsc-cal-day-i {
    box-sizing: border-box;
  }

  .mbsc-cal .mbsc-cal-day-date {
    text-align: center;
    box-sizing: content-box;
  }

  /* Flex layout */

  .mbsc-cal-hdr,
  .mbsc-cal-btn-w,
  .mbsc-cal-days-c,
  .mbsc-cal-days,
  .mbsc-cal-tabbed .mbsc-fr-c .mbsc-sc-whl-gr-c {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .mbsc-cal-btn-w,
  .mbsc-cal-month,
  .mbsc-cal-year,
  .mbsc-cal-days,
  .mbsc-cal-days > div {
    -webkit-box-flex: 1;
    -webkit-flex: 1 auto;
    -ms-flex: 1 auto;
    flex: 1 auto;
  }

  .mbsc-cal-c {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
  }

  /* Header */

  .mbsc-cal-btn-w {
    width: 50%;
  }

  .mbsc-cal-month,
  .mbsc-cal-year {
    width: 1%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }

  .mbsc-cal-btn.mbsc-disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  /* Week days */

  .mbsc-cal-days {
    line-height: 1.875em;
    text-align: center;
  }

  .mbsc-cal-days > div {
    width: 14.285715%;
  }

  .mbsc-cal-body {
    position: relative;
  }

  .mbsc-cal-scroll-c {
    overflow: hidden;
    height: 100%;
  }

  .mbsc-cal-scroll {
    width: 100%;
    height: 100%;
  }

  .mbsc-cal-slide {
    position: absolute;
    z-index: 0; // Needed by Edge
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .mbsc-cal-slide:first-child {
    position: relative;
  }

  .mbsc-cal-table {
    display: table;
    width: 100%;
    height: 100%;
    table-layout: fixed;
    // Needed for iOS Safari to prevent animation flicker
    -webkit-transform: translateZ(0);
  }

  .mbsc-cal-row {
    display: table-row;
  }

  .mbsc-cal-cell {
    display: table-cell;
    vertical-align: middle;

    &:focus {
      outline: 0;
    }
  }

  .mbsc-cal-picker {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0.9375em 0;
    transition: all 0.2s ease-in-out;
    will-change: opacity;
    background-color: white;

    .mbsc-cal-cell {
      width: 33.333333%;
      height: 25%;
      cursor: pointer;
    }
  }

  .mbsc-cal-h {
    visibility: hidden;
    opacity: 0;
  }

  .mbsc-cal-day {
    max-width: 14.285715%; // if only width is used, layout is broken with week numbers displayed
    height: 16.666667%; // IE needs it in fullscreen
    position: relative;
    text-align: center;
    cursor: pointer;
  }

  .mbsc-cal-day-i {
    position: relative;
    height: 100%;
  }

  .mbsc-cal-day-markup {
    // Prevent margin-collapse
    overflow: hidden;
  }

  .mbsc-cal-day-diff .mbsc-cal-day-date {
    opacity: 0.5;
  }

  .mbsc-cal-week-view .mbsc-cal-day-date,
  .mbsc-cal-day-diff.mbsc-selected .mbsc-cal-day-date {
    opacity: 1;
  }

  .mbsc-cal-cell.mbsc-disabled {
    cursor: not-allowed;

    .mbsc-cal-cell-txt {
      opacity: 0.2;
    }
  }

  /* Hide outer days */

  .mbsc-cal-hide-diff {
    .mbsc-cal-day-diff {
      cursor: default;

      .mbsc-cal-day-i {
        visibility: hidden;
      }
    }
  }

  /* Week numbers */

  .mbsc-ltr .mbsc-cal-weeks .mbsc-cal-days {
    margin-left: 1.625em;
  }

  .mbsc-rtl .mbsc-cal-weeks .mbsc-cal-days {
    margin-right: 1.625em;
  }

  .mbsc-cal-week-nr {
    width: 1.625em;
    text-align: center;
  }

  /* Multiple month */

  .mbsc-cal-multi {
    .mbsc-cal-hdr {
      display: block;
    }

    .mbsc-cal-btn-w {
      width: auto;
    }

    .mbsc-cal-days,
    .mbsc-cal-slide {
      padding: 0 0.5em;
    }
  }

  /* Liquid mode */

  .mbsc-fr-liq {
    .mbsc-cal-c {
      display: block;
    }
  }

  /* Full screen mode */

  .mbsc-calendar.mbsc-fr-liq.mbsc-fr-center {
    .mbsc-fr-overlay {
      display: none;
    }

    .mbsc-fr-w {
      border-radius: 0;
      padding-left: constant(safe-area-inset-left);
      padding-left: env(safe-area-inset-left);
      padding-right: constant(safe-area-inset-right);
      padding-right: env(safe-area-inset-right);
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
    }
  }

  /* Tabs */

  .mbsc-cal-tabs-c {
    display: none;
  }

  .mbsc-cal-tabs {
    display: table;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .mbsc-cal-tab {
    position: relative;
    width: 33.33%;
    display: table-cell;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
  }

  .mbsc-cal-tabbed {
    .mbsc-fr-c {
      position: relative;
    }

    .mbsc-w-p {
      transition: opacity 0.2s ease-in-out;
    }

    .mbsc-sc-whl-gr-c {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      max-width: none;
      height: 100%;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-box-align: center;
      align-items: center;
    }

    .mbsc-cal-c,
    .mbsc-cal-tabs-c {
      display: block;
    }

    /* During positioning */
    .mbsc-fr-pos {
      .mbsc-sc-whl-gr-c {
        width: auto;
      }
    }
  }

  /* Marks */

  .mbsc-cal-has-marks .mbsc-cal-day {
    padding-bottom: 0.25em;
  }

  .mbsc-cal-marks {
    position: absolute;
    left: 0;
    right: 0;
    height: 0.375em;
    margin-top: -0.125em;
    overflow: hidden;
    white-space: nowrap;
  }

  .mbsc-cal-mark {
    display: inline-block;
    vertical-align: top;
    width: 0.25em;
    height: 0.25em;
    margin: 0.0625em;
    border-radius: 0.25em;
  }

  /* Text & icons */

  // .mbsc-cal-has-txt .mbsc-cal-day {
  //     padding-bottom: 1em;

  //     .mbsc-cal-icons {
  //         background: none;
  //     }
  // }

  .mbsc-cal-txt-w {
    position: absolute;
    z-index: 2;
    pointer-events: none;
  }

  .mbsc-ltr .mbsc-cal-txt {
    text-align: left;
  }

  .mbsc-rtl .mbsc-cal-txt {
    text-align: right;
  }

  .mbsc-cal-txt,
  .mbsc-cal-txt-ph,
  .mbsc-cal-txt-more {
    position: relative;
    height: 1.6em;
    line-height: 1.6em;
    overflow: hidden;
    padding: 0 0.3em;
    font-size: 0.625em;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 0.1em 0.2em 0.1em;
  }

  .mbsc-cal-day .mbsc-cal-txt.mbsc-cal-txt-only {
    color: inherit;
    text-align: center;
  }

  .mbsc-no-touch .mbsc-cal-txt:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s;
  }

  .mbsc-no-touch .mbsc-cal-txt-only:before {
    display: none;
  }

  .mbsc-no-touch .mbsc-cal-txt.mbsc-hover:before {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .mbsc-no-touch .mbsc-cal-txt-more:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .mbsc-cal-txt-more {
    text-align: left;
    transition: background-color 0.2s;
  }

  // .mbsc-cal-txt {
  //     position: absolute;
  //     left: .1em;
  //     right: .1em;
  //     height: 1.6em;
  //     line-height: 1.6em;
  //     overflow: hidden;
  //     padding: 0 .2em;
  //     font-size: .625em;
  //     white-space: nowrap;
  // }

  .mbsc-cal .mbsc-cal-btn {
    position: relative;
    z-index: 1;
  }
}
