@import '../core/exports';
@import '../scroller/scroller';
// @import "../input/input";

@include exports('select') {
  .mbsc-sel-hdn {
    position: absolute;
    height: 1px !important;
    width: 1px !important;
    margin: 0 !important;
    padding: 0 !important;
    left: 0;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  .mbsc-sel .mbsc-sel-gr {
    padding: 0 0.25em;
    opacity: 1;
    font-weight: bold;
    text-align: left;
  }

  .mbsc-sel .mbsc-fr-c {
    position: relative;
  }

  .mbsc-sel-multi .mbsc-sc-whl-w .mbsc-sc-whl-l {
    display: none;
  }

  .mbsc-sel-empty {
    display: none;
    opacity: 0.6;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2em;
    margin-top: -1em;
    font-size: 1.5em;
    line-height: 2em;
  }

  .mbsc-sel-empty-v {
    display: block;
  }

  .mbsc-sel-filter-clear {
    position: absolute;
    top: 0;
    height: 100%;
    width: 1.5em;
    display: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.125em;
  }

  .mbsc-sel-filter-show-clear {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .mbsc-sel-input-wrap {
    position: relative;
  }

  .mbsc-sel .mbsc-ltr .mbsc-sel-filter-cont .mbsc-sel-filter-input {
    padding-right: 1.5em;
  }

  .mbsc-sel .mbsc-rtl .mbsc-sel-filter-cont .mbsc-sel-filter-input {
    padding-left: 1.5em;
  }

  .mbsc-ltr .mbsc-sel-filter-clear {
    right: 0;
  }

  .mbsc-rtl .mbsc-sel-filter-clear {
    left: 0;
  }

  .mbsc-sel.mbsc-fr-pointer.mbsc-no-touch .mbsc-sc-whl .mbsc-sc-itm.mbsc-sel-gr:hover {
    background: none;
  }
}
