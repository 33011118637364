@import "../core/exports";
@import "../scroller/scroller.mobiscroll";
@import "./calbase.mobiscroll.colors";
@import "./calbase";


@include exports("calbase.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-cal-c {
      padding: .5em;
    }

    .mbsc-cal-btn-w {
      font-size: 1.125em;
      line-height: 2.222223em;
    }

    .mbsc-cal .mbsc-cal-btn {
      width: 2.666667em;
      height: 2.666667em;
      line-height: 2.666667em;
      padding: 0;
      border-radius: 2em;
      margin: -.222222em;
    }

    .mbsc-cal-picker {
      border-top: 1px solid transparent;

      .mbsc-cal-cell-i {
        display: inline-block;
        height: 2em;
        padding: 0 1em;
        line-height: 2em;
        border-radius: 2em;
        white-space: nowrap;
      }
    }

    .mbsc-cal-days > div {
      font-size: .6875em;
    }

    .mbsc-cal-day-date {
      display: inline-block;
      width: 2.153847em;
      height: 2.153847em;
      margin: .307692em 0;
      line-height: 2.153847em;
      font-size: .8125em;
      border: 2px solid transparent;
      border-radius: 2em;
    }

    /* Week numbers */
    .mbsc-cal-week-nr {
      width: 2.363637em;
      font-size: .6875em;
    }

    /* Tabs */
    .mbsc-cal-tabs-c {
      line-height: 1.875em;
      padding: .5em .5em 0 .5em;
      text-transform: uppercase;
    }

    .mbsc-cal-tab {
      font-size: .7em;
      padding: 0 1.2em;
    }
  }

  @include mbsc-mobiscroll-calbase(mobiscroll, $mbsc-mobiscroll-colors);
}
