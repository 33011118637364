@import "../core/exports";
@import "../core/common";
@import "../core/animation";
@import "../core/icons";

@include exports("frame") {

  .mbsc-fr-w,
  .mbsc-fr-overlay {
    -webkit-transform: translateZ(0);
  }

  .mbsc-fr {
    pointer-events: none;
    z-index: 99998;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
  }

  .mbsc-fr-focus {
    outline: 0;
  }

  .mbsc-fr-lock-ctx {
    position: relative;
  }

  .mbsc-fr-lock.mbsc-fr-lock-ios {
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
  }

  .mbsc-fr-pos {
    visibility: hidden;
  }

  .mbsc-fr-scroll {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .mbsc-fr-popup {
    max-width: 98%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    font-size: 12px;
    text-shadow: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
  }

  .mbsc-rtl {
    direction: rtl;
  }


  /* Box sizing */

  .mbsc-fr-popup,
  .mbsc-fr-btn-cont,
  .mbsc-fr-arr {
    box-sizing: border-box;
  }

  .mbsc-fr .mbsc-fr-w {
    box-sizing: content-box;
  }

  .mbsc-fr-w {
    min-width: 256px;
    max-width: 100%;
    overflow: hidden;
    text-align: center;
    font-family: arial, verdana, sans-serif;
  }


  /* Modal overlay */

  .mbsc-fr,
  .mbsc-fr-persp,
  .mbsc-fr-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .mbsc-fr-lock .mbsc-fr-persp {
    -ms-touch-action: none;
    touch-action: none;
  }

  .mbsc-fr-lock-ctx > .mbsc-fr,
  .mbsc-fr-lock-ctx .mbsc-fr-persp,
  .mbsc-fr-lock-ctx .mbsc-fr-overlay {
    position: absolute;
  }

  .mbsc-fr-persp {
    pointer-events: auto;
    overflow: hidden;
  }

  .mbsc-fr-overlay {
    z-index: 1;
    background: rgba(0, 0, 0, .7);
  }


  /* Liquid mode */

  .mbsc-fr-liq .mbsc-fr-popup {
    max-width: 100%;
  }


  /* Top/Bottom mode */

  .mbsc-fr-top .mbsc-fr-popup,
  .mbsc-fr-bottom .mbsc-fr-popup {
    width: 100%;
    max-width: 100%;
  }

  .mbsc-fr-top .mbsc-fr-w,
  .mbsc-fr-bottom .mbsc-fr-w {
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
  }

  .mbsc-fr-bottom .mbsc-fr-w {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }

  .mbsc-fr-top .mbsc-fr-popup {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
  }


  /* Inline mode */

  .mbsc-fr-inline {
    position: relative;
    pointer-events: auto;
    z-index: 0;
  }

  .mbsc-fr-inline .mbsc-fr-popup {
    position: static;
    max-width: 100%;
  }


  /* Bubble mode */

  .mbsc-fr-bubble,
  .mbsc-fr-bubble .mbsc-fr-persp {
    position: absolute;
  }

  .mbsc-fr-bubble .mbsc-fr-arr-w {
    position: absolute;
    z-index: 1;
    left: 0;
    width: 100%;
    overflow: hidden;
  }

  .mbsc-fr-bubble-top .mbsc-fr-arr-w {
    top: 100%;
  }

  .mbsc-fr-bubble-bottom .mbsc-fr-arr-w {
    bottom: 100%;
  }

  .mbsc-fr-bubble .mbsc-fr-arr-i {
    margin: 0 1.75em;
    position: relative;
    direction: ltr;
  }

  .mbsc-fr-bubble .mbsc-fr-arr {
    display: block;
  }

  .mbsc-fr-arr {
    display: none;
    position: relative;
    left: 0;
    width: 2em;
    height: 2em;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    margin-left: -1em;
  }

  .mbsc-fr-bubble-bottom .mbsc-fr-arr {
    top: 1.333334em;
  }

  .mbsc-fr-bubble-top .mbsc-fr-arr {
    top: -1.333334em;
  }

  .mbsc-fr-hdn {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    overflow: hidden;
  }


  /* Header */

  .mbsc-fr-hdr {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }


  /* Buttons */

  .mbsc-fr-btn {
    overflow: hidden;
    display: block;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: top;
  }

  .mbsc-fr-btn-e {
    cursor: pointer;
  }

  .mbsc-fr-btn.mbsc-disabled {
    cursor: not-allowed;
  }


  /* Button container */

  .mbsc-fr-btn-cont {
    display: table;
    width: 100%;
    text-align: center;
    white-space: normal;
  }

  .mbsc-fr-btn-cont .mbsc-disabled {
    opacity: .3;
  }


  /* Button wrapper */

  .mbsc-fr-btn-w {
    vertical-align: top;
    display: table-cell;
    position: relative;
    z-index: 5;
  }

  .mbsc-fr-btn-w .mbsc-fr-btn:before {
    padding: .375em;
  }

  /* Desktop view */

  .mbsc-fr-pointer {

    /* Embedded components */
    &.mbsc-fr .mbsc-fr-w .mbsc-fr-inline .mbsc-fr-w {
      box-shadow: none;
      border-radius: 0;
    }

    .mbsc-ltr .mbsc-fr-w,
    .mbsc-ltr .mbsc-sc-whl .mbsc-sel-gr {
      text-align: left;
    }

    .mbsc-rtl .mbsc-fr-w,
    .mbsc-rtl .mbsc-sc-whl .mbsc-sel-gr {
      text-align: right;
    }

    &.mbsc-fr-top .mbsc-fr-w,
    &.mbsc-fr-bottom .mbsc-fr-w {
      pointer-events: auto;
      display: inline-block;
      margin-top: 3em;
      margin-bottom: 3em;
      max-width: 98%;
    }

    &.mbsc-fr-top .mbsc-fr-popup,
    &.mbsc-fr-bottom .mbsc-fr-popup {
      text-align: center;
      pointer-events: none;
    }

    &.mbsc-fr-bubble .mbsc-fr-arr-w {
      display: none;
    }

    .mbsc-sel-empty {
      text-align: center;
    }
  }
}
