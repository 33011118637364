@import "../core/exports";
@import "../core/mobiscroll";
@import "../scroller/scroller.mobiscroll.colors";

$mbsc-mobiscroll-calendar-mark: $mbsc-calendar-mark-light !default;
$mbsc-mobiscroll-dark-calendar-mark: $mbsc-calendar-mark-dark !default;

$mbsc-mobiscroll-colors: map-merge($mbsc-mobiscroll-colors, (
  'calendar-mark': $mbsc-mobiscroll-calendar-mark,
));
$mbsc-mobiscroll-dark-colors: map-merge($mbsc-mobiscroll-dark-colors, (
  'calendar-mark': $mbsc-mobiscroll-dark-calendar-mark,
));

@mixin mbsc-mobiscroll-calbase($theme, $params) {
  @include exports("calbase.#{$theme}.colors") {

    @include mbsc-mobiscroll-scroller($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    $background-param: map-get($params, 'frame-background');
    $text-param: map-get($params, 'frame-text');
    $accent-param: map-get($params, 'frame-accent');
    $mark-param: map-get($params, 'calendar-mark');

    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);
    $mark: if($mark-param, $mark-param, $text);

    .mbsc-#{$theme} {
      .mbsc-cal-picker {
        background: $background;
      }

      .mbsc-cal-days > div {
        border-bottom: 1px solid $accent;
        color: $accent;
      }

      .mbsc-cal-today {
        color: $accent;
      }

      /* Hover, focus */
      &.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt,
      &.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover,
      .mbsc-cal-cell:focus .mbsc-cal-cell-txt {
        background: rgba($accent, .3);
      }

      /* Selected */
      .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
        background: $accent;
        color: $background;
      }

      .mbsc-cal-day-colored.mbsc-selected .mbsc-cal-day-date {
        border-color: $accent;
      }

      /* Week numbers */
      .mbsc-cal-week-nr {
        color: $accent;
      }

      .mbsc-cal-tab {
        border: 1px solid $accent;
        color: $text;
      }

      .mbsc-fr-w .mbsc-cal-tabs-c .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected {
        background: $accent;
        color: $background;
      }

      /* Marks */
      .mbsc-cal-mark {
        background: $mark;
      }

      .mbsc-cal-txt {
        color: $background;
        background: $text;
      }

      .mbsc-cal-icons {
        color: $text;
      }

    }
  }
}
