@import "../core/exports";
@import "../calbase/calbase";

@include exports("range") {

  .mbsc-range-btn,
  .mbsc-range-btn-t,
  .mbsc-range-btn-c {
    box-sizing: border-box;
  }

  .mbsc-range-btn-t {
    display: table;
    width: 100%;
  }

  .mbsc-range-btn-c {
    display: table-cell;
    width: 50%;
  }

  .mbsc-range-btn {
    text-decoration: none;
    text-align: center;
    display: block;
    cursor: pointer;
  }

  .mbsc-cal-hide-diff .mbsc-cal-day-diff:after {
    display: none;
  }
}
